import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import uuid from 'react-uuid';
import FirstStepIcon from '../../images/icons/first-step-icon.png';
import SecondStepIcon from '../../images/icons/second-step-icon.png';
import ThirdStepIcon from '../../images/icons/third-step-icon.png';
import videoImg from '../../images/hero/video-hero.svg';
import { getBrowserStorage, redirect, setCookieStorage, getCookieStorage } from '../../common';
import ModalZipform from '../core/zipform/ModalZipform';
import FullscreenModal from '../fullscreen-modal/FullscreenModal';
import envConstants from '../../globals/constants/environment-constants';

const ThreeSteps = (): JSX.Element => {
  const sendVideoTracking = (eventName) => {
    if (window._waEvents) {
      window._waEvents.push('trackEvent', {
        page: {
          action: 'track video',
        },
        video: {
          title: 'Compare plans and enroll online in 3 easy steps',
          id: '5QHSjpdIh_w',
        },
        events: [eventName],
      });
    }
  };
  const playerRef = useRef(null);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [played25Percent, setPlayed25Percent] = useState(false);
  const [played50Percent, setPlayed50Percent] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const QA_SECTION = 'BottomEasySteps';
  const modalContent = <ModalZipform qaSection={QA_SECTION} insuranceType="medicare" />;
  const [IsModalShowing, setIsModalShowing] = useState(false);
  const [isShowBanner, setIsShowBanner] = useState(false);

  const handlePlay = () => {
    if (!hasPlayed) {
      sendVideoTracking('video_start');
      setHasPlayed(true);
      setPlayed25Percent(false);
      setPlayed50Percent(false);
    }
  };
  const handleProgress = (state: any) => {
    const { played } = state;
    if (played >= 0.25 && !played25Percent) {
      sendVideoTracking('video_25');
      setPlayed25Percent(true);
    }
    if (played >= 0.5 && !played50Percent) {
      sendVideoTracking('video_50');
      setPlayed50Percent(true);
    }
  };
  const handleEnded = () => {
    sendVideoTracking('video_end');
    setHasPlayed(false);
  };
  const handleClick = () => {
    setShowVideo(true);
  };

  const openZipModal = () => {
    if (getBrowserStorage('zipValue')) {
      redirect.toEhmp({
        zipValue: getBrowserStorage('zipValue'),
        countyValue: getBrowserStorage('countyValue'),
      });
    } else {
      setIsModalShowing(true);
    }
  };
  const getFF4JResult = (featureTag) => {
    if (!featureTag) {
      return;
    }
    try {
      const ehiVisitorIdInCookie = getCookieStorage('ehiVisitorId');
      let ehiVisitorIdHeader = uuid().replace(/-/g, '').toUpperCase();
      if (ehiVisitorIdInCookie) {
        ehiVisitorIdHeader = ehiVisitorIdInCookie;
      } else {
        setCookieStorage('ehiVisitorId', ehiVisitorIdHeader);
      }
      const ff4jAPIEndpoint = `https://www.${envConstants.SUBDOMAIN}ehealthinsurance.com/ifp-online-api/v1/ff4j/features/${featureTag}`;
      axios
        .get(ff4jAPIEndpoint, {
          headers: {
            VisitorToken: `IFP_API ehi_visitor_id="${ehiVisitorIdHeader}"`,
          },
        })
        .then((response) => {
          const toggleStatus = response.data.enabled;
          setIsShowBanner(toggleStatus);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log('Fetch FF4J configuration fail.');
    }
  };

  useEffect(() => {
    getFF4JResult('ehiHomeBottomThreeSteps');
  }, []);

  return (
    <section
      className={`px-3 pt-6 pb-12 md:px-6 text-gray-dark bg-white font-open-sans lg:py-20 ${isShowBanner ? '' : 'hidden'}`}
      data-wa-depth="Compare plans and enroll in 3 easy steps: depthTracking"
    >
      <div className="max-w-screen-xl mx-auto overflow-hidden">
        <h2 className="flex justify-center mb-6 md:mb-9 lg:mb-10 text-center mx-3 text-20 leading-28 md:text-32 md:leading-40 xl:text-40 xl:leading-56 font-bold text-green-darker">
          Compare plans and enroll online in 3 easy steps
        </h2>
        <div className="md:flex">
          <div className="mt-6 md:mt-0 md:w-2/5 lg:w-1/2 md:p-3 lg:pr-6">
            <div className="bg-[#F8F8F8] shadow rounded-[8px] text-center p-3">
              <div className="relative bg-[#4DCB2A] rounded-[8px]">
                <div className="w-full video-parent">
                  {showVideo ? (
                    <div className="embed-responsive w-full h-[153px] md:h-[317px]">
                      <ReactPlayer
                        ref={playerRef}
                        url="https://www.youtube.com/watch?v=5QHSjpdIh_w"
                        playing
                        muted
                        width="100%"
                        height="100%"
                        controls
                        onPlay={handlePlay}
                        onProgress={handleProgress}
                        onEnded={handleEnded}
                      />
                    </div>
                  ) : (
                    <img
                      className="w-[174px] h-[153px] md:w-[349px] md:h-[317px] mx-auto lg:ml-[84px] pt-3 cursor-pointer video-image w-full"
                      role="presentation"
                      src={videoImg}
                      alt="video"
                      onClick={handleClick}
                    />
                  )}
                </div>
              </div>
              <p className="text-18 leading-28 lg:text-20 xl:text-24 xl:leading-32 mt-3 font-semibold font-poppin">
                See how it works!
              </p>
              <p className="text-green-800 text-12 leading-16">2 min video</p>
            </div>
          </div>
          <div className="md:w-3/5 lg:w-1/2 mt-6 md:mt-0">
            <div className="flex md:pr-3 mb-6">
              <img
                className="mx-auto mb-3 md:m-0 w-[56px] h-[56px] lg:w-[64px] lg:h-[64px]"
                src={FirstStepIcon}
                alt="first step icon"
              />
              <div className="ml-4 text-left">
                <h3 className="text-18 mb-1 lg:text-20 leading-28 xl:text-24 xl:leading-32 font-bold text-green-darker">
                  Match with plans that fit your needs
                </h3>
                <p className="w-full max-w-[415px] font-open-sans md:max-w-none text-16 leading-24 xl:text-18 xl:leading-28 mx-auto text-green-darker">
                  Enter your zip code and see plans in your area.
                </p>
              </div>
            </div>
            <div className="flex md:pr-3 mb-6">
              <img
                className="mx-auto mb-3 lg:m-0 w-[56px] h-[56px] lg:w-[64px] lg:h-[64px]"
                src={SecondStepIcon}
                alt="second step icon"
              />
              <div className="ml-4 text-left">
                <h3 className="text-18 mb-1 lg:text-20 leading-28 xl:text-24 xl:leading-32 font-bold text-green-darker">
                  Compare plans side-by-side
                </h3>
                <p className="w-full max-w-[415px] font-open-sans md:max-w-none text-16 leading-24 xl:text-18 xl:leading-28 mx-auto text-green-darker">
                  Review plans that match your needs, and see side-by-side comparisons from the nation’s top insurance companies.
                </p>
              </div>
            </div>
            <div className="flex md:pr-3 mb-6">
              <img
                className="mx-auto mb-3 lg:m-0 w-[56px] h-[56px] lg:w-[64px] lg:h-[64px]"
                src={ThirdStepIcon}
                alt="third step icon"
              />
              <div className="ml-4 text-left">
                <h3 className="text-18 mb-1 lg:text-20 leading-28 xl:text-24 xl:leading-32 font-bold text-green-darker">
                  Enroll online
                </h3>
                <p className="w-full max-w-[415px] font-open-sans md:max-w-none text-16 leading-24 xl:text-18 xl:leading-28 mx-auto text-green-darker">
                  Licensed insurance agents are available via chat, if you need help with your application.
                </p>
              </div>
            </div>
            <div className="pt-4 px-3 md:px-0 text-center">
              <button
                type="button"
                data-wa-link="Plan Prescriber: Find Medicare Plans"
                className="bg-[#FA6200] text-white text-16 md:text-20 leading-24 md:leading-28 font-bold p-3 rounded-3xl w-full md:w-auto md:px-6 hover:underline min-w-full md:min-w-[380px]"
                onClick={openZipModal}
                data-qa={`${QA_SECTION}:Button:FindPlans`}
              >
                See plans <span className="hidden md:inline">in my area</span>
              </button>
              <FullscreenModal
                isShowing={IsModalShowing}
                setIsShowing={setIsModalShowing}
                modalContent={modalContent}
                containerClasses="bg-white"
                qaSection={QA_SECTION}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThreeSteps;
