import React, { useLayoutEffect, useState, useRef } from 'react';
import MainLayoutRedesign from '../core/layout/MainLayoutRedesign';
import HeroRedesign from '../hero/HeroRedesign';
import CarrierLogosColorful from '../carrier-logos/CarrierLogosColorful';
import EhealthLiveAdvise from '../ehealth-live-advise/EhealthLiveAdvise';
import InsuranceTypesRedesign from '../insurance-types/InsuranceTypesRedesign';
import ThreeSteps from '../three-steps/ThreeSteps';
import Savings from '../savings/Savings';
import SixReasonsRedesign from '../six-reasons/SixReasonsRedesign';
import BottomTxtRedesign from '../bottom-txt/BottomTxtRedesign';
import BannerAd from '../BannerAd/BannerAd';
import BannerAdMedicare from '../BannerAdMedicare/BannerAdMedicare';
import useWindowResize from '../../hooks/useWindowResize';
import constants from '../../globals/constants/application-constants';
import OepBanner from '../oep-banner/OepBanner';
import useSharedTabState from '../../hooks/tabState';
import BottomMedicareTxt from '../bottom-medicare-txt/BottomMedicareTxt';
import AppProvider from '../../globals/context/AppContext';
import ThreeStepsBflow from '../three-steps/ThreeStepsBflow';
import envConstants from '../../globals/constants/environment-constants';

const PureIndex = () => {
  const { active } = useSharedTabState();
  const [defaultTab, setDefaultTab] = useState(active);
  const windowInnerwidth = useWindowResize(true);
  let isDesktop = windowInnerwidth >= constants.DESKTOP_BREAKPOINT;
  const [serviceHours, setServiceHours] = React.useState(null);

  useLayoutEffect(() => {
    isDesktop = windowInnerwidth >= constants.DESKTOP_BREAKPOINT;
  }, [windowInnerwidth]);
  const ref = useRef(null);
  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const fetchServiceHours = async () => {
    const customerServiceHoursAPI = `${envConstants.SITE_URL}/ifp-api/misc/support-number/work-hours?type=SG_CC`;

    try {
      const req = await fetch(customerServiceHoursAPI);

      if (!req.ok) {
        throw new Error(req.statusText);
      }

      const resp: string[] = await req.json();
      const data = resp ? resp.join('\n') : '';

      setServiceHours(data);
    } catch (error) {
      console.error('[fetchServiceHours]:', error);
    }
  };
  React.useEffect(() => {
    if (serviceHours === null) {
      fetchServiceHours();
    }
  }, []);

  return (
    <AppProvider>
      <MainLayoutRedesign>
        <BannerAd />
        <OepBanner />
        <HeroRedesign defaultTab={defaultTab} serviceHours={serviceHours} />
        <CarrierLogosColorful />
        <BannerAdMedicare handleScroll={handleScroll} />
        <SixReasonsRedesign />
        {active === 0 && <Savings />}
        {active === 0 ? <EhealthLiveAdvise /> : <ThreeSteps />}
        <InsuranceTypesRedesign />
        <BottomTxtRedesign serviceHours={serviceHours} />
        {active === 0 && <ThreeStepsBflow />}
        <div className="relative">
          <div className="absolute top-[-160px] left-0 w-full h-[1px] z-[-10]" ref={ref} />
          <BottomMedicareTxt />
        </div>
      </MainLayoutRedesign>
    </AppProvider>
  );
};

export default PureIndex;
